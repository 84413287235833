import axios from 'axios'

const baseURL = process.env.VUE_APP_CREDITOS_API

const api_token = process.env.VUE_APP_CREDITOS_TOKEN

const headers = {
    'authorization': api_token,
};

const http = axios.create({ baseURL, headers })

http.interceptors.response.use(({ data }) => data)

export default http