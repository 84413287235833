<template>
  <form
    :class="{ 'was-validated': errors.any() }"
    @submit.prevent="submit"
    class="credito"
    novalidate
    ref="form"
  >
    <div class="row">
      <div class="title col-sm-12">
        <h4>Solicita tu Crédito</h4>
      </div>
    </div>

    <div class="row">
      <!---left-->
      <div class="col-sm-6">
        <div class="row">
          <div class="subtitle col-sm-12">Datos de tu crédito</div>
        </div>

        <!-- MONTO DEL PRESTAMO -->
        <div class="row">
          <div class="col-sm-12">
            <input
              v-model="form.monto_prestamo"
              type="text"
              name="monto_prestamo"
              id="monto_prestamo"
              class="form-control"
              v-money="form.monto_prestamo !== null ? money : null"
              placeholder="Monto del crédito"
              required
              data-vv-as="monto del préstamo"
              v-validate="'min_money:200000|max_money:5000000'"
            />
            <div class="invalid-feedback">
              {{ errors.first("monto_prestamo") }}
            </div>
          </div>
        </div>

        <!-- PLAZO -->
        <div class="row">
          <div class="col-sm-12">
            <select
              v-model="form.plazo"
              name="plazo"
              id="plazo"
              class="form-control"
              required
              v-validate
            >
              <option :value="null" disabled selected>Plazo deseado</option>
              <option value="1">1 año</option>
              <option value="2">2 años</option>
              <option value="3">3 años</option>
              <option value="4">4 años</option>
              <option value="5">5 años</option>
            </select>
            <div class="invalid-feedback">{{ errors.first("plazo") }}</div>
          </div>
        </div>

        <div class="row">
          <div class="subtitle col-sm-12">Datos del solicitante</div>
        </div>

        <!-- TIPO DE PERSONA -->
        <div class="row justify-content-around">
          Tipo de persona:
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="tipo_persona"
              id="pf"
              value="PF"
              v-model="tipo_persona"
              ref="tipo_persona"
            />
            <label class="form-check-label" for="pf"> Persona Física </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="tipo_persona"
              id="pm"
              value="PM"
              v-model="tipo_persona"
              ref="tipo_persona"
            />
            <label class="form-check-label" for="pm"> Persona Moral </label>
          </div>
        </div>

        <!-- NOMBRE & APELLIDO PATERNO -->
        <div class="row" v-if="tipo_persona === 'PF'">
          <div class="col-sm-6">
            <input
              v-model="form.nombre"
              type="text"
              name="nombre"
              id="nombre"
              class="form-control"
              placeholder="Nombres"
              v-validate="'required|alpha_spaces'"
              key="nombre"
            />
            <div class="invalid-feedback">{{ errors.first("nombre") }}</div>
          </div>
          <div class="col-sm-6">
            <input
              v-model="form.paterno"
              type="text"
              name="paterno"
              id="paterno"
              class="form-control"
              placeholder="Apellido paterno"
              data-vv-as="apellido paterno"
              key="paterno"
              v-validate="'required|alpha_spaces'"
            />
            <div class="invalid-feedback">
              {{ errors.first("paterno") }}
            </div>
          </div>
        </div>

        <!-- RAZON SOCIAL -->
        <div class="row" v-if="tipo_persona === 'PM'">
          <div class="col-sm-12">
            <input
              v-model="form.nombre"
              type="text"
              name="nombre"
              id="nombre"
              class="form-control"
              placeholder="Razón Social"
              key="nombre"
              data-vv-as="razón social"
              v-validate="'required'"
            />
            <div class="invalid-feedback">{{ errors.first("nombre") }}</div>
          </div>
        </div>

        <!-- APELLIDO MATERNO, RFC & INGRESO MENSUAL -->
        <div class="row">
          <div class="col-sm-6" v-if="tipo_persona === 'PF'">
            <input
              v-model="form.materno"
              type="text"
              name="materno"
              id="materno"
              class="form-control"
              placeholder="Apellido materno"
              data-vv-as="apellido materno"
              key="materno"
              v-validate="'required|alpha_spaces'"
            />
            <div class="invalid-feedback">
              {{ errors.first("materno") }}
            </div>
          </div>
          <div class="col-sm-6" v-if="tipo_persona === 'PM'">
            <input
              class="form-control"
              name="rfc"
              placeholder="RFC"
              v-model="form.rfc"
              type="text"
              data-vv-as="RFC"
              v-validate="'required|alpha_num|min:12|max:13'"
              key="rfc"
            />
            <div class="invalid-feedback">{{ errors.first("rfc") }}</div>
          </div>
          <div class="col-sm-6">
            <input
              v-model="form.ingreso_mensual"
              type="text"
              name="ingreso_mensual"
              id="ingreso_mensual"
              class="form-control"
              placeholder="Ingreso mensual"
              v-money="form.ingreso_mensual !== null ? money : null"
              data-vv-as="ingreso mensual"
              required
              v-validate
            />
            <div class="invalid-feedback">{{ errors.first("ingreso_mensual") }}</div>
          </div>
        </div>

        <!-- EMAIL & TELEFONO -->
        <div class="row">
          <div class="col-sm-6">
            <input
              v-model="form.email"
              type="email"
              name="email"
              id="email"
              class="form-control"
              placeholder="Email"
              required
              v-validate
            />
            <div class="invalid-feedback">{{ errors.first("email") }}</div>
          </div>
          <div class="col-sm-6">
            <input
              v-model="form.telefono"
              type="text"
              name="telefono"
              id="telefono"
              class="form-control"
              placeholder="Teléfono"
              data-vv-as="teléfono"
              required
              v-validate="'numeric|min:10|max:16'"
            />
            <div class="invalid-feedback">{{ errors.first("telefono") }}</div>
          </div>
        </div>

        <!-- GIRO DEL NEGOCIO -->
        <div class="row">
          <div class="col-sm-12">
            <select
              v-model="form.giro_negocio_id"
              name="giro_negocio_id"
              data-vv-as="giro del negocio"
              id="giro_negocio_id"
              class="form-control"
              required
              v-validate
            >
              <option :value="null" disabled selected>Giro del negocio</option>
              <optgroup
                v-for="(options, section) in giros_negocios"
                :key="section"
                :label="section"
              >
                <option
                  v-for="option in options"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.nombre }}
                </option>
              </optgroup>
              >
            </select>
            <div class="invalid-feedback">
              {{ errors.first("giro_negocio_id") }}
            </div>
          </div>
        </div>

        <div class="row d-hidden">
          <div class="subtitle col-sm-12">
            Como prefieres que te contactemos
          </div>
        </div>

        <!-- METODO DE CONTACTO -->
        <div class="row">
          <div class="col-sm-12">
            <select
              v-model="form.metodo_preferido_contacto"
              name="metodo_preferido_contacto"
              id="metodo_preferido_contacto"
              class="form-control"
              data-vv-as="forma de contacto"
              required
              v-validate
            >
              <option value="telefono">Teléfono</option>
              <option value="whatsapp">WhatsApp</option>
              <option value="email">Correo electrónico</option>
            </select>
          </div>
        </div>

        <!-- POLITICAS DE PRIVACIDAD -->
        <div class="row form-check">
          <div class="col-sm-12">
            <input
              v-model="form.acepta_politicas_privacidad"
              type="checkbox"
              name="acepta_politicas_privacidad"
              id="acepta_politicas_privacidad"
              class="form-check-input"
              required
              v-validate
            />
            <label class="form-check-label" for="acepta_politicas_privacidad">
              Acepto las <a href="" title="">políticas de privacidad</a>
            </label>
            <div class="invalid-feedback">
              {{ errors.first("acepta_politicas_privacidad") }}
            </div>
          </div>
        </div>
      </div>
      <!--right-->
      <div class="col-sm-6">
        <div class="row">
          <div class="subtitle col-sm-12">Datos de la garantía</div>
        </div>

        <!-- CODIGO POSTAL & COLONIAS -->
        <div class="row">
          <div class="col-sm-6">
            <input
              v-model="form.cp"
              type="number"
              name="cp"
              id="cp"
              class="form-control"
              placeholder="Código postal"
              @keyup="searchCP"
              autocomplete="off"
              data-vv-as="código postal"
              required
              v-validate="'numeric|digits:5'"
            />
            <div class="invalid-feedback">{{ errors.first("cp") }}</div>
          </div>
          <div class="col-sm-6">
            <select
              v-model="form.colonia"
              name="colonia"
              id="colonia"
              class="form-control"
              required
              v-validate
            >
              <option :value="null" disabled selected>Colonia</option>
              <option
                v-for="colonia in colonias"
                :value="colonia"
                :key="colonia"
              >
                {{ colonia }}
              </option>
            </select>
            <div class="invalid-feedback">{{ errors.first("colonia") }}</div>
          </div>
        </div>

        <!-- MUNICIPIO & ESTADO -->
        <div class="row">
          <div class="col-sm-6">
            <input
              v-model="form.entidad"
              type="text"
              name="entidad"
              id="entidad"
              class="form-control"
              placeholder="Estado"
              required
              v-validate
              disabled
            />
            <div class="invalid-feedback">{{ errors.first("entidad") }}</div>
          </div>
          <div class="col-sm-6">
            <input
              v-model="form.delegacion"
              type="text"
              name="delegacion"
              id="delegacion"
              class="form-control"
              placeholder="Municipio / Alcaldía"
              required
              data-vv-as="municipio"
              v-validate
              disabled
            />
            <div class="invalid-feedback">
              {{ errors.first("municipio") }}
            </div>
          </div>
        </div>

        <!-- CALLE & NUMERO EXTERIOR -->
        <div class="row">
          <div class="col-sm-6">
            <input
              v-model="form.calle"
              type="text"
              name="calle"
              id="calle"
              class="form-control"
              placeholder="Calle"
              required
              v-validate
            />
            <div class="invalid-feedback">{{ errors.first("calle") }}</div>
          </div>
          <div class="col-sm-6">
            <input
              v-model="form.numero"
              type="text"
              name="numero"
              id="numero"
              data-vv-as="número exterior"
              class="form-control"
              placeholder="Número exterior"
              required
              v-validate
            />
            <div class="invalid-feedback">{{ errors.first("numero") }}</div>
          </div>
        </div>

        <!-- MAPA -->
        <div class="row">
          <div class="col-sm-12">
            <p>
              Verifica que la ubicacion del marcador, coicida con la ubicacion
              de tu garantia
            </p>
            <Map
              id="ubicacion"
              class="map"
              :address="direccion_completa"
              @marker-position-changed="positionMarkerChanged"
            />
          </div>
        </div>

        <!-- TIPO DE PROPIEDAD -->
        <div class="row">
          <div class="col-sm-6">
            <select
              v-model.number="form.tipogarantia_id"
              name="tipogarantia_id"
              id="tipogarantia_id"
              class="form-control"
              data-vv-as="tipo de propiedad"
              required
              v-validate
            >
              <option :value="null" disabled selected>Tipo de propiedad</option>
              <option value="1">Casa Sola</option>
              <option value="2">Casa Condominio</option>
              <option value="3">Departamento</option>
              <option value="4">Local Comercial</option>
              <option value="7">Oficina</option>
            </select>
            <div class="invalid-feedback">
              {{ errors.first("tipogarantia_id") }}
            </div>
          </div>
          <div class="col-sm-6">
            <input
              v-model="form.valor"
              type="text"
              name="valor"
              id="valor"
              class="form-control"
              v-money="form.valor !== null ? money : null"
              placeholder="Valor de la propiedad"
              data-vv-as="valor de propiedad"
              required
              v-validate
            />
            <div class="invalid-feedback">
              {{ errors.first("valor") }}
            </div>
          </div>
        </div>

        <!-- SUPERFICIE DEL TERRENO & CONSTRUIDA -->
        <div class="row">
          <div class="col-sm-6">
            <input
              data-vv-as="metros del terreno"
              name="superficie_terreno"
              placeholder="m² de terreno"
              type="number"
              class="form-control"
              aria-required="true"
              aria-invalid="false"
              v-model="form.superficie_terreno"
              v-validate="'required|decimal|min_value:30|max_value:1000'"
            />
            <div class="invalid-feedback">
              {{ errors.first("superficie_terreno") }}
            </div>
          </div>
          <div class="col-sm-6">
            <input
              data-vv-as="metros contruidos"
              name="area_construida"
              placeholder="m² construidos"
              type="number"
              class="form-control"
              aria-required="true"
              aria-invalid="false"
              v-model="form.area_construida"
              v-validate="'required|decimal|min_value:30|max_value:1000'"
            />
            <div class="invalid-feedback">
              {{ errors.first("area_construida") }}
            </div>
          </div>
        </div>

        <!-- NUMERO DE NIVELES & NUMERO DE PISOS  -->
        <div class="row">
          <div class="col-sm-6">
            <input
              data-vv-as="número de nivel"
              name="numero_nivel"
              placeholder="Número de nivel"
              type="number"
              class="form-control"
              aria-required="true"
              aria-invalid="false"
              v-model="form.numero_nivel"
              v-validate="'required|min_value:1'"
              :disabled="[1, 2].includes(form.tipogarantia_id)"
            />
            <div class="invalid-feedback">
              {{ errors.first("numero_nivel") }}
            </div>
          </div>
          <div class="col-sm-6">
            <input
              data-vv-as="número de pisos"
              name="numero_pisos"
              placeholder="Número de pisos"
              type="number"
              class="form-control"
              aria-required="true"
              aria-invalid="false"
              v-model="form.numero_pisos"
              v-validate="'required|min_value:1'"
            />
            <div class="invalid-feedback">
              {{ errors.first("numero_pisos") }}
            </div>
          </div>
        </div>

        <!-- VISTA EXTERIOR, ROOF GARDEN & BALCON -->
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                id="vista_exterior"
                class="custom-control-input"
                v-model="form.vista_exterior"
              />
              <label for="vista_exterior" class="custom-control-label"
                >Tiene vista exterior</label
              >
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                id="roof_garden"
                class="custom-control-input"
                v-model="form.roof_garden"
              />
              <label for="roof_garden" class="custom-control-label"
                >Tiene roof garden</label
              >
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                id="balcon"
                class="custom-control-input"
                v-model="form.balcon"
              />
              <label for="balcon" class="custom-control-label"
                >Tiene Balcón</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 text-center">
        <button id="obtenerCredito" type="submit">Obtener mi crédito</button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 info">
        CAT Promedio informativo y para fines de comparación 33.3% para un
        crédito por un monto de $1,000,000 m.n. a 60 meses calculado a mayo
        2019, el pago promedio mensual podría ser de $34,300.31 m.n. Este puede
        variar dependiendo del monto, plazo y perfil del cliente al momento de
        solicitar un crédito. Tasas desde 24% a 28% anual. Plazos de pago de 1 a
        5 años. Monto de $200 mil a $5 millones de pesos.
      </div>
    </div>
  </form>
</template>

<script>
import { VMoney } from "v-money";
import { isAxiosError } from "axios";
import Map from "@/components/Map.vue";

import CodigoPostal from "@/api/creditos/codigo_postal.api";
import Solicitud from "@/api/creditos/solicitudes.api";
import GiroNegocio from "@/api/creditos/giros_negocios.api";

const defaults = {
  // Crédito
  monto_prestamo: null,
  plazo: null,

  // Prospecto
  nombre: null,
  paterno: null,
  materno: null,
  email: null,
  telefono: null,
  rfc: null,
  ingreso_mensual: null,
  giro_negocio_id: null,
  metodo_preferido_contacto: "telefono",
  acepta_politicas_privacidad: false,

  // Garantia
  cp: null,
  colonia: null,
  entidad: null,
  delegacion: null,
  calle: null,
  numero: null,
  latitud: null,
  longitud: null,
  tipogarantia_id: null,
  valor: null,
  superficie_terreno: null,
  area_construida: null,
  numero_nivel: null,
  numero_pisos: null,
  vista_exterior: false,
  roof_garden: false,
  balcon: false,
}

export default {
  components: {
    VMoney,
    Map,
  },

  data() {
    return {
      tipo_persona: "PF",
      form: {...defaults},
      colonias: [],
      giros_negocios: [],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      referente: window.location.href,
    };
  },

  computed: {
    direccion_completa() {
      return [
        this.form.calle,
        this.form.numero,
        this.form.colonia,
        this.form.delegacion,
        this.form.cp,
        this.form.entidad,
      ]
        .filter(Boolean)
        .join(" ")
        .trim();
    },
  },

  mounted() {
    this.loadGirosNegocios();
  },

  methods: {
    async loadGirosNegocios() {
      const loading = this.$loading.show({ container: this.$refs.form });
      try {
        this.giros_negocios = await GiroNegocio.all({ grouped: true });
      } finally {
        loading.hide();
      }
    },
    async submit() {

      if (!(this.$validator && (await this.$validator.validateAll()))) {
        return;
      }

      const loading = this.$loading.show({ container: this.$refs.form });
      try {
        // Para persona moral no aplica tener apellidos.
        if (this.tipo_persona === "PM") {
          this.form.paterno = "ND";
          this.form.materno = "ND";
        }

        const { solicitud_id } = await Solicitud.create({
          ...this.form,

          // Campos moneda.
          monto_prestamo: this.formatNumber(this.form.monto_prestamo),
          ingreso_mensual: this.formatNumber(this.form.ingreso_mensual),
          valor: this.formatNumber(this.form.valor),

          // Defaults
          medioinformacion_id: process.env.VUE_APP_MEDIO_INFORMACION,
          promotor_id: 2,
          propia: 1,
          descripcion_solicitud: "Solicitud enviada vía micrositio OnisVida",
          importe_pre_autorizado: 0,
          acepta_pre_autorizado: 0,

          // Referente
          referente: this.referente,
        })

        this.$swal({
          icon: 'success',
          title: 'Se ha registrado la solicitud correctamente',
          html:`Uno de nuestros ejecutivos te contactará en breve<br/>Solicitud con ID: <strong>${solicitud_id}</strong>`,
          confirmButtonText: 'Aceptar',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        // Reset formulario
        this.$validator.reset();
        this.form = defaults

      } 
      
      catch (e) {
        let message = e.message

        if (isAxiosError(e) && 'errores' in e.response.data) {
          message = e.response.data.errores.join(', ')
        }

        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
        })
      }

      finally {
        loading.hide();
      }
    },
    async searchCP(e) {
      if (e.target.value.length !== 5) {
        return;
      }

      const loading = this.$loading.show({ container: this.$refs.form });
      try {
        const response = await CodigoPostal.find(e.target.value);

        this.form.entidad = response.estado;
        this.form.delegacion = response.municipio;
        this.colonias = response.colonias;
      } catch (e) {
        if (isAxiosError(e) && e.response.data.length === 0 ) {
          return this.$swal({
            icon: "warning",
            position: "center",
            html: `Lo sentimos tu código postal aún no se encuentra dentro de nuestra cobertura. 
            Te sugerimos consultar nuestra sección de preguntas frecuentes dando clic 
            <a href='https://bienparabien.com/faq' target="_blank">aquí</a>.`,
          });
        } 
        
        let message = e.message

        if (isAxiosError(e) && 'errores' in e.response.data) {
          message = e.response.data.errores.join(', ')
        }

        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
        })
        
      } finally {
        loading.hide();
      }
    },
    positionMarkerChanged(position) {
      this.form.latitud = position.lat();
      this.form.longitud = position.lng();
    },
    formatNumber(value) {
      if (! isNaN(value)) {
        return value;
      }

      return Number(value.replace(/[^0-9.-]+/g, ""));
    },
  },

  directives: {
    money: VMoney,
  },
};
</script>

<style>
</style>