import http from './http'

export default {
    /**
     * Find zipcode from sepomex catalog.
     * @param {Number} code 
     * @returns {Promise}
     */
    find(code) {
        return http.get(`codigo_postal/${code}`)
    }
}