<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a href="/" class="navbar-brand">
        <img class="logo_a" src="/images/coru-y-bpb.png" alt="Logo Bien para Bien" title="Bien para Bien" />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navMain"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navMain" class="collapse navbar-collapse">
        <ul class="navbar-nav">

          <li class="nav-item">
            <a href="" class="nav-link" v-scroll-to="'#ventajas'">Beneficios</a>
          </li>
          <li class="nav-item">
            <a href="" class="nav-link" v-scroll-to="'#requisitos'">Nosotros</a>
          </li>

          <li class="nav-item">
            <a href="" class="nav-link nav-active" v-scroll-to="'#registro'">Iniciar solicitud</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {

}
</script>
