<template>
  <div id="app">
    <div class="content">
      <Header />
      <div class="main">
        <div class="component">
          <Hooper
            :autoPlay="false"
            :playSpeed="5000"
            :wheelControl="false"
            :mouseDrag="false"
            :touchDrag="false"
            :keysControl="false"
            :hoverPause="false">
              <Slide><img src="/images/coru-imagen_new.png" alt="Bien para Bien. Créditos que hacen bien." /></Slide>
          </Hooper>

          <div id="credito" class="credito-info">
            <div class="row">
              <div class="col-md-12 subtitle">
                Créditos de hasta <br><b><span>5 millones</span>.<br> Pon en orden tus finanzas.</b>
              </div>
            </div>
          </div>

          <div id="registro" class="container">
            <Form />
          </div>

          <div id="benefits" class="info-general full-container">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <p class="subtitle"> Somos la opción más flexible, más confiable y segura.<br /><br /><strong>¿Cuáles son las características?:</strong></p>
                  <ul>
                    <li>Flexibilidad con tu Buró de Crédito</li>
										<li>Sin comprobante de ingresos</li>
										<li>Sin comisiones</li>
										<li>Tasa fija anual</li>
										<li>Hasta 5 años de plazo de pago</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <h3>¿Cómo obtener tu crédito?</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="card text-center">
                    <div class="card-body">
                      <h5 class="card-title">1</h5>
                      <p class="card-text">Llena tu solicitud</p>
                    </div>
                  </div>
                  <div class="card text-center">
                    <div class="card-body">
                      <h5 class="card-title">2</h5>
                      <p class="card-text">Espera la confirmación de tu crédito y llena tu expediente.</p>
                    </div>
                  </div>
                  <div class="card text-center">
                    <div class="card-body">
                      <h5 class="card-title">3</h5>
                      <p class="card-text">Disfruta de tu crédito.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="ribbon-stores" class="full-container">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
										<p class="px-3">Opciones de uso para tu crédito:</p>
									</div>
                  <div class="row">
										<div class="col">
										  <p>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">PYMES</span>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">Pagos y deudas</span>
                      </p>
										</div>
										<div class="col">
										  <p>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">Renueva tu casa</span>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">Educación</span>
                      </p>
										</div>
										<div class="col">
										  <p>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">Consolida deudas</span>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">Urgencia médica</span>
                      </p>
										</div>
										<div class="col">
										  <p>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">Vacacionar</span>
                        <span class="d-block"><img src="/images/ckeckW.png" style="width: 35px; margin-top: -6px">O para lo que necesites</span>
                      </p>
										</div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id="ventajas" class="descript container">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="title-bpb center-text">¿Quién es Bien para Bien?</h3>
                <h2>
                   Desde 2014 ayudamos a miles de familias mexicanas a acceder a un préstamo a través de su casa, oficina o local comercial.
                </h2>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-6">
								<div class="panel">
									<span class="icon">
										<img src="/images/fortaleza.png" alt="Valores">
									</span>
									<ul>
										<li>Fortaleza financiera.</li>
										<li>Integridad.</li>
										<li>Inclusión.</li>
										<li>Innonvación.</li>
									</ul>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6">
								<div class="panel">
									<span class="icon">
										<img src="/images/inmuebles.png" alt="Misión">
									</span> Facilitar a millones de familias mexicanas que poseen una vivienda o un bien inmueble un crédito.
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6">
								<div class="panel">
									<span class="icon">
										<img src="/images/fintech.png" alt="Visión">
									</span> Ser la empresa líder en América Latina en préstamos con garantía, reconocida por impulsar la inclusión financiera, por su innovación tecnológica, en productos y en efectividad de procesos.
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6">
								<div class="panel">
									<span class="icon">
										<img src="/images/tecnologia.png" alt="Tecnología">
									</span><br>Utilizamos la tecnología a favor de nuestros clientes, agilizamos procesos utilizando uno de los más novedosos sistemas de Machine Learning para el proceso de entrevista.
								</div>
							</div>
            </div>
          </div>
          <div class="logos container">
            <div class="row">
              <div class="col-sm-2 col-md-2 offset-md-2">
                <a href="https://www.buro.gob.mx/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/logo_buro.png" alt="Ir al sitio de Buró de Crédito" />
                </a>
              </div>
              <div class="col-sm-2 col-md-2">
                <a href="http://www.condusef.gob.mx/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/condusef.png" alt="Ir al sitio de CONDUSEF" />
                </a>
              </div>
              <div class="col-sm-2 col-md-2">
                <a href="https://www.fintechmexico.org/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/Logo_fintech_mx.png" alt="Ir al sitio de Fintech Mexico" />
                </a>
              </div>
              <div class="col-sm-2 col-md-2">
                <a href="http://asofom.mx/asociados/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/asofom.png" alt="Ir al sitio de ASOFOM" />
                </a>
              </div>
            </div>
          </div>
        </div> <!-- .component end -->
      </div> <!-- .main end -->
      <Footer />
    </div> <!-- .content end -->
  </div> <!-- #app end -->
</template>

<script>
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'

import Footer from '@/components/Footer.vue'
import Form from '@/components/Form.vue'
import Header from '@/components/Header.vue'

export default {
  components: {
    Footer,
    Form,
    Header,
    Hooper, Slide,
  }
}
</script>

<style lang="scss">
@import './assets/main';
</style>
