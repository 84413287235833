<template>
  <div class="footer">
    Todos los derechos reservados. &copy; Bien para Bien SAPI de C.V, SOFOM
    ENR. {{ new Date().getFullYear() }}
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>