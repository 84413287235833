import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';

Vue.use(VeeValidate, {
    validity: true,
})

Validator.localize('es', {
    custom: {
        acepta_politicas_privacidad: {
            required: 'Debes de aceptar las políticas de privacidad para poder continuar.'
        },
    }, 
    messages: {
        ... es.messages,
        decimal: (field) => `El campo ${field} debe ser numérico.`
    }
});

Validator.extend('max_money', {
    getMessage (field, args) {
        const [num] = args
        const mount = Vue.filter('currency')(num, '$ ', 0)
        return `El campo ${field} debe de ser de ${mount} o menor.`
    },
    validate (value, args) {
        // currency to number
        value = Number(value.replace(/[^0-9.-]+/g,""));
        return !isNaN(value) && value <= args[0]
    }
});

Validator.extend('min_money', {
    getMessage (field, args) {
        const [num] = args
        const mount = Vue.filter('currency')(num, '$ ', 0)
        return `El campo ${field} debe de ser de ${mount} o superior.`
    },
    validate (value, args) {
        // currency to number
        value = Number(value.replace(/[^0-9.-]+/g,""));
        return !isNaN(value) && value >= args[0]
    }
});
