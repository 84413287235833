<template>
  <div :id="id" class="map"></div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    address: {
      type: String
    },
    center: {
      type: Object,
      default() {
        return {
          lat: 19.3997719,
          lng: -99.1502502,
        }
      }
    },
    zoom: {
      type: Number,
      default() {
        return 8
      }
    }
  },

  data() {
    return {
      map: null,
      geocoder: null,
      marker: null,
    }
  },

  mounted() {
    this.loadMap()
  },
  
  watch: {
    address: {
      handler(address, old) {
        if (address === '') {
          return;
        }

        this.geocoder.geocode({ address }, this.resolveAddress)
      },
      inmediate: true,
    }
  },

  methods: {
    resolveAddress(results, status) {
      if (status !== "OK") {
        return;
      }

      const location = results[0].geometry.location

      this.map.setCenter(location)
      this.map.setZoom(18)
      this.marker.setPosition(location)

      this.$emit('geocode', { results, status })
    },

    loadMap() {
      this.geocoder = new google.maps.Geocoder()

      this.map = new google.maps.Map(document.getElementById(this.id), {
        zoom: this.zoom,
        mapTypeControl: false,
        center: this.center,
      });

      this.loadMarker()
    },

    loadMarker() {
      this.marker = new google.maps.Marker({
				map: this.map,
				position: this.center,
				draggable: true
			})

      this.marker.addListener('position_changed', this.positionMarkerChanged)
      this.marker.addListener('dragend', this.dragendMarker);
    },

    dragendMarker(event) {
      this.$emit('marker-dragend', event)
    },

    positionMarkerChanged() {
      this.$emit('marker-position-changed', this.marker.getPosition())
    },

    

    
  }
}
</script>

<style scoped>
.map {
	height: 20em;
	width: 100%;
	background: black;
	border: 1px solid white;
	margin: 10px auto;
	overflow: visible;
}
</style>
